import shopifyGenericDefaults from '../shopify-generic/config.js';

shopifyGenericDefaults.Widgets.find((w) => w.name === 'RelatedItems').count = 4;

function InitFunc() {
  if (window.document.querySelector('#cm_results, #cm-brand-list, #cm_manufacturers')) {
    const unwantedPageWrappers = [
      ['#MainContent .rte', 'rte', 'rte'],
      ['#MainContent .page-width.page-width--narrow', 'page-width--narrow'],
      ['#MainContent .container.container-narrow', 'container-narrow'],
    ];

    unwantedPageWrappers.forEach(([elemSelector, className]) => {
      const wrapperElem = window.document.querySelector(elemSelector);
      wrapperElem?.classList.remove(className);
    });
  }
}

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  InitFunc,
  storeId: 'functionwerks-dev',
};
