
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('product-card', { 'class': 'block card-wrapper product-card-wrapper' }, _createElement('div', { 'className': 'product-card card card--card card--shape-square card--text-center card--media color-scheme-2 gradient' }, _createElement('div', { 'className': 'card__inner ratio' }, _createElement('div', { 'className': 'card__media' }, _createElement('div', { 'className': 'media media--transparent media--hover-effect' }, _createElement('img', {
                    'className': 'card__image motion-reduce object-center object-contain',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'onError': this.onImageError,
                    'alt': this.removeHTML(this.title)
                }), this.image2 ? _createElement('img', {
                    'className': 'card__image motion-reduce card__image-hover object-contain',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '530'
                }) : null)), _createElement('div', { 'className': 'card__content card__content--badge' }, this.on_sale ? _createElement('div', {
                    'className': 'card__badge inline-flex gap-05 flex-wrap justify-start top left',
                    'key': '850'
                }, _createElement('span', { 'className': 'badge badge--sale badge--bottom-left badge--rounded' }, '\n            ', this.calcDiscount(this.price, this.compare_at_price), '% off\n          ')) : null)), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__information' }, _createElement('span', { 'className': 'visually-hidden' }, 'Vendor:'), _createElement('div', mergeProps({ 'className': 'product-vendor mb-1 caption-with-letter-spacing light t6' }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('h3', { 'className': 'card__heading text-body t3 font-bold' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'full-unstyled-link uppercase'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'card-information' }, this.inventory_quantity ? _createElement('div', {
                    'className': 'card-inventory-container',
                    'key': '1674'
                }, _createElement('div', {
                    'className': 'product__inventory product__inventory--pill mb-05 t7 no-js-hidden',
                    'role': 'status'
                }, this.inventory_quantity <= window.Convermax.inventoryThreshold ? [
                    [_createElement('svg', {
                            'width': '20',
                            'height': '20',
                            'aria-hidden': 'true',
                            'className': 'product-inventory--low',
                            'key': '19680'
                        }, _createElement('circle', {
                            'className': 'bg-circle',
                            'cx': '10',
                            'cy': '10',
                            'r': '10',
                            'fill': '#fcf0e7'
                        }), _createElement('circle', {
                            'cx': '10',
                            'cy': '10',
                            'r': '6',
                            'stroke': 'rgb(255, 255, 255)',
                            'strokeWidth': '1',
                            'fill': '#ee995e'
                        }))],
                    '\n                Only ',
                    this.inventory_quantity,
                    ' left\n              '
                ] : null, this.inventory_quantity > window.Convermax.inventoryThreshold ? [
                    [_createElement('svg', {
                            'width': '20',
                            'height': '20',
                            'aria-hidden': 'true',
                            'className': 'product-inventory--normal',
                            'key': '24370'
                        }, _createElement('circle', {
                            'className': 'bg-circle',
                            'cx': '10',
                            'cy': '10',
                            'r': '10',
                            'fill': 'rgba(85,175,16, 0.2)'
                        }), _createElement('circle', {
                            'cx': '10',
                            'cy': '10',
                            'r': '6',
                            'stroke': 'rgb(255, 255, 255)',
                            'strokeWidth': '1',
                            'fill': 'rgba(85,175,16, 0.6)'
                        }))],
                    '\n                ',
                    this.inventory_quantity,
                    ' in stock\n              '
                ] : null)) : null, _createElement('div', { 'className': 'price t2 uppercase' }, _createElement('div', { 'className': 'price__container' }, !this.on_sale ? _createElement('div', {
                    'className': 'price__regular',
                    'key': '2954'
                }, _createElement('span', { 'className': 'price-item price-item--regular' }, this.price_varies ? _createElement('span', { 'key': '3085' }, 'From: ') : null, '\n                  ', this.formatPrice(this.price), '\n                ')) : null, this.on_sale ? _createElement('div', {
                    'className': 'price__sale',
                    'key': '3239'
                }, _createElement('span', { 'className': 'price-item price-item--sale price-item--last' }, this.price_varies ? _createElement('span', { 'key': '3380' }, 'From: ') : null, ' ', this.formatPrice(this.price), '\n                '), _createElement('span', {}, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price)))) : null)))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []